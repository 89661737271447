<template>
  <div id="app" ref="container">
    <!-- <div class="audio" v-if="showAudio">
      <aplayer 
        autoplay 
        mini 
        float
        :music="{ src: 'duoxingyun.mp3' }"
      />
    </div> -->
    <!-- <ul class="list">
      <li v-for="(img, index) in list" :key="index">
        <img style="width: 50px; height: 50px" :src="img" @click="toggle(img, index)">
      </li>
    </ul> -->

    <img
      class="vrIcon"
      src="@/assets/images/vr/vr-icon.png"
      @click="showToggleScene = true"
    />

    <van-popup v-model="showToggleScene" position="bottom" round closeable>
      <div class="popup bc-full-x">
        <div class="popup-title bc-flex-center">场景切换</div>
        <ul class="content bc-full-x">
          <li 
            class="bc-box-border bc-full-x" 
            style="padding-left: 18px;"
            v-for="(item, index) in sceneList"
            :key="item.advert_code"
            @click="toggleScene(item, index)"
          >
            {{item.desc}}
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Viewer } from "photo-sphere-viewer";
// import * as adapter from 'photo-sphere-viewer/dist/adapters/equirectangular-video.js';
// import * as plugin from 'photo-sphere-viewer/dist/plugins/video.js';

import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
// import 'photo-sphere-viewer/dist/plugins/video.css';
// import Aplayer from 'vue-aplayer'

import { getAdvertList } from '@/api/vr'

export default {
  // components: {
  //   Aplayer
  // },
  data() {
    return {
      // list: [
      //   'pano_7000.jpg',
      //   'test.png',
      //   'aaa.jpg'
      // ],
      activeIndex: 0,
      // showAudio: false,
      viewer: null,
      showToggleScene: false,
      sceneList: []
    };
  },
  mounted() {
    this.getAdvertList()
  },
  methods: {
    destroyViewer() {
      this.$once("hook:destroyed", () => {
        this.viewer.destroy();
      });
    },
    async getAdvertList() {
      const { advert_code } = this.$route.query;
      const { data } = await getAdvertList({
        advert_location_code_list: ["103", "104", "105", "215", "216"],
        extension_type: '',
        extension_classify_lev1: '',
        extension_type: ''
      })

      const newData = (data['215'] || []).find(item => item.advert_code === advert_code)
      this.sceneList = newData && newData.vr_panorama

      this.initViewer()
    },
    initViewer() {
      // 图片全景预览
      this.viewer = new Viewer({
        container: this.$refs.container,
        panorama: this.sceneList[0].imgs,
        autorotateDelay: 1000  // 延时几秒自动播放
        // panorama: '/images/20221012_162905_370.jpg',
        // panorama: '/images/20221012_162922_560.jpg'
      });

      // 播放视频
      // this.viewer = new Viewer({
      //   container: this.$refs.app,
      //   adapter: [adapter.EquirectangularVideoAdapter, {
      //     autoplay: true, // default
      //     muted: false, // default
      //   }],
      //   panorama: {
      //     source: 'https://mudin.github.io/vue-vr/assets/ClashofClans.mp4', // also supports webm
      //   },
      //   plugins: [
      //     plugin.VideoPlugin
      //   ]
      // });

      this.destroyViewer();

      // this.viewer.once('ready', () => {
      //   this.showAudio = true
      // });
    },
    toggleScene(item, index) {
      this.showToggleScene = false

      if (this.activeIndex === index) return
      this.activeIndex = index

      this.viewer.setPanorama(item.imgs)
    }
  }
};
</script>

<style lang='scss' scoped>
html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
/* .audio{
    position: absolute;
    right: 10px;
    top: 30px;
    z-index: 999;
  }
  .list{
    position: fixed;
    left: 10px;
    bottom: 55px;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 51px;
    height: 150px;
    border: 1px solid yellow;
    border-radius: 3px;
  }
  img{
    vertical-align: top;
    cursor: pointer;
  } */
.vrIcon {
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 5px;
  right: 40px;
  z-index: 1000;
}

.popup {
  .popup-title {
    padding: 18px 0 32px;
    font-size: 16px;
    font-weight: 500;
  }
  .content{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      line-height: 45px;
      border-bottom: 1px solid #F5E3E3;
      font-size: 15px;
      color: #202122;
    }
  }
}
</style>
